* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background: deepskyblue;
  transition: 0.4s ease-out;
}

.App.warm {
  background: coral;
}

main {
  background-image: linear-gradient(to bottom, rgba(0,0,0,.2), rgba(0,0,0,.75));
  min-height: 100vh;
  padding: 25px;
}

.search {
  margin: 0 0 75px;
  width: 100%;
}

.search .search-input {
  appearance: none;
  background: none;
  background-color: rgba(255, 255, 255, .5);
  border: none;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 5px rgba(0, 0, 0, .2);
  color: #313131;
  display: block;
  font-size: 20px;
  margin-top: -25px;
  outline: none;
  padding: 15px;
  transition: .4s ease;
  width: 100%;
}

.search .search-input:focus {
  background-color: rgba(255, 255, 255, .75);
}

.location-wrap .location {
  color: #f1f1f1;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  text-shadow: 3px 3px rgba(50, 50, 70, .5);
}

.location-wrap .date {
  color: #f1f1f1;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  text-shadow: 2px 2px rgba(50, 50, 70, .5);
}

.weather-wrap {
  text-align: center;
}

.weather-wrap .temp {
  background-color: rgba(255, 255, 255, .2);
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, .2);
  color: #f1f1f1;
  display: inline-block;
  font-size: 102px;
  font-weight: 900;
  margin: 30px auto;
  padding: 15px 25px;
  position: relative;
  text-align: center;
  text-shadow: 3px 6px rgba(50, 50, 70, .5);
}

.weather-wrap .weather {
  color: #f1f1f1;
  font-size: 48px;
  font-weight: 700;
  text-shadow: 3px 3px rgba(50, 50, 70, .5);
}